<template>
  <div class="listings-header">
    <img v-if="collection && (collection.image && collection.image.length > 0)" class="header-image" :src="`${collection.image}`">
    <div v-if="collection && (!collection.image || collection.image.length == 0)" class="header-image">{{ collection.name }}</div>
    <img v-if="page == 'ltc20' && loadImage(ltc20.ticker)" class="header-image" :src="loadImage(ltc20.ticker)">
    <div v-if="page == 'ltc20' && !loadImage(ltc20.ticker)" class="header-image">{{ ltc20.ticker }}</div>
    <template v-if="runes">
      <img v-if="!headerImageError && runes && runes.parent" class="header-image" loading="lazy" :src="`https://ordinalslite.com/content/${runes.parent}`" @error="handleImageError">
      <img v-else-if="!headerImageError && runes && !runes.parent" class="header-image" loading="lazy" src="@/assets/rune.png">
      <div v-else class="header-image" loading="lazy" style="font-size: 4rem; line-height: 1;">
        {{ runes.symbol }}
      </div>
    </template>
    <div v-if="collection">
      <h1 class="name">{{ collection.name }}</h1>
      <div class="description">{{ collection.description }}</div>
      <div class="socials">
        <a v-if="collection.social_external" :href="`${collection.social_external}`" target="_blank">
          <font-awesome-icon :icon="['fas', 'globe']" />
        </a>
        <a v-if="collection.social_twitter" :href="`${collection.social_twitter}`" target="_blank">
          <font-awesome-icon :icon="['fab', 'twitter']" />
        </a>
        <a v-if="collection.social_discord" :href="`${collection.social_discord}`" target="_blank">
          <font-awesome-icon :icon="['fab', 'discord']" />
        </a>
      </div>
    </div>
    <div v-if="!collection">
      <h1 v-if="page == 'litemaps'" class="name" style="text-transform: capitalize;">{{ page }}</h1>
      <h1 v-if="page == 'ltc20'" class="name" style="text-transform: uppercase;">{{ collection_name }}</h1>
      <h1 v-if="page == 'runes'" class="name" style="text-transform: capitalize;">{{ runes.spaced_rune }}</h1>
      <router-link v-if="page == 'runes'" :to="{ name: 'rune', params: { id: runes.id }}" class="description">{{ runes.id }}</router-link>
    </div>
  </div>
  <div class="details">
    <div>
      <div class="detail-item">
        <div v-if="page != 'ltc20' && page != 'runes'" class="detail-value"><tippy to="parent">${{ (satToBtc((listings[0]) ? listings[0].price : 0) * usd_rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</tippy><img src="@/assets/Litecoin.png" class="litecoin-icon">{{ (listings[0]) ? satToBtc(listings[0].price).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 8 }) : 0 }}</div>
        <div v-if="page == 'ltc20'" class="detail-value"><tippy to="parent">${{ (satToBtc(Number(ltc20.floor_price)) * usd_rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</tippy><img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(Number(ltc20.floor_price)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 8 }) }}</div>
        <div v-if="page == 'runes'" class="detail-value"><tippy to="parent">${{ (satToBtc(Number(runes.market.floor_price)) * usd_rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</tippy><img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(Number(runes.market.floor_price)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 8 }) }}</div>
        <div class="detail-text">{{ page == 'ltc20' || page == 'runes' ? "Price" : "Floor Price" }}</div>
      </div>
      <div v-if="page == 'ltc20' || page == 'runes'" class="detail-item">
        <div v-if="page == 'ltc20'" class="detail-value" :style="{ color: ltc20.change_24h > 0 ? 'lawngreen' : (ltc20.change_24h < 0 ? 'tomato' : 'inherit') }">{{ ltc20.change_24h }}%</div>
        <div v-if="page == 'runes'" class="detail-value" :style="{ color: runes.market.change_24h > 0 ? 'lawngreen' : (runes.market.change_24h < 0 ? 'tomato' : 'inherit') }">{{ runes.market.change_24h }}%</div>
        <div class="detail-text">24h %</div>
      </div>
      <div v-if="collection || page == 'runes'" class="detail-item">
        <div v-if="collection" class="detail-value"><tippy to="parent">${{ (satToBtc(collection.total_volume) * usd_rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</tippy><img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(collection.total_volume).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 8 }) }}</div>
        <div v-if="page == 'runes'" class="detail-value"><tippy to="parent">${{ (satToBtc(runes.market.total_volume) * usd_rate).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</tippy><img src="@/assets/Litecoin.png" class="litecoin-icon">{{ satToBtc(runes.market.total_volume).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 8 }) }}</div>
        <div class="detail-text">Total Volume</div>
      </div>
      <div v-if="page == 'ltc20' || page == 'runes'" class="detail-item">
        <div v-if="page == 'ltc20'" class="detail-value"><tippy to="parent">${{ (ltc20.supply * Number(satToBtc(ltc20.floor_price) * usd_rate)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}</tippy><img src="@/assets/Litecoin.png" class="litecoin-icon">{{ (ltc20.supply * Number(satToBtc(ltc20.floor_price))).toLocaleString('en-US') }}</div>
        <div v-if="page == 'runes'" class="detail-value"><tippy to="parent">${{ (amountToDecimal(Number(runes.premine + runes.mints * (runes.terms ? (runes.terms.amount ?? 0) : 0)), runes.divisibility) * satToBtc(Number(runes.market.floor_price)) * usd_rate).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}</tippy><img src="@/assets/Litecoin.png" class="litecoin-icon">{{ (amountToDecimal(Number(runes.premine + runes.mints * (runes.terms ? (runes.terms.amount ?? 0) : 0)), runes.divisibility) * satToBtc(Number(runes.market.floor_price))).toLocaleString('en-US') }}</div>
        <div class="detail-text">Market Cap</div>
      </div>
      <div v-if="collection" class="detail-item">
        <div class="detail-value">{{ Number(collection.trades_24h).toLocaleString('en-US') }}</div>
        <div class="detail-text">Total Trades</div>
      </div>
      <div class="detail-item">
        <div v-if="collection" class="detail-value">{{ Number(collection.supply).toLocaleString('en-US') }}</div>
        <div v-if="page == 'litemaps'" class="detail-value">{{ Number(block_height).toLocaleString('en-US') }}</div>
        <div v-if="page == 'ltc20'" class="detail-value">{{ Number(ltc20.supply).toLocaleString('en-US') }}</div>
        <div v-if="page == 'runes'" class="detail-value">{{ amountToDecimal(Number(runes.premine + runes.mints * (runes.terms ? (runes.terms.amount ?? 0) : 0)), runes.divisibility).toLocaleString('en-US') }}</div>
        <div class="detail-text">Supply</div>
      </div>
      <div v-if="page == 'ltc20'" class="detail-item">
        <div class="detail-value">{{ ltc20.holders.toLocaleString('en-US') }}</div>
        <div class="detail-text">Holders</div>
      </div>
      <div v-if="page == 'ltc20'" class="detail-item">
        <div class="detail-value">{{ ltc20.transactions.toLocaleString('en-US') }}</div>
        <div class="detail-text">Transactions</div>
      </div>
      <div v-if="page != 'ltc20' && page != 'runes'" class="detail-item">
        <div class="detail-value">{{ Number(total).toLocaleString('en-US') }}<span v-if="collection"> ({{ (total / collection.supply * 100).toFixed(0) }}%)</span></div>
        <div class="detail-text">Listed</div>
      </div>
      <div v-if="collection" class="detail-item">
        <div class="detail-value">{{ Number(collection.royalties) }}%</div>
        <div class="detail-text">Royalties</div>
        <tippy to="parent">
          Creator Earnings
        </tippy>
      </div>
    </div>
  </div>
  <div class="tabs-wrapper hide-scroll">
    <ul class="tabs">
      <li :class="{ 'active': tab == 0 }" @click="showTab(0)">Listings</li>
      <li :class="{ 'active': tab == 1 }" @click="showTab(1)">Activity</li>
      <li v-if="page == 'ltc20'" :class="{ 'active': tab == 2 }" @click="showTab(2)">Holders</li>
      <li v-if="page == 'ltc20'" :class="{ 'active': tab == 3 }" @click="showTab(3)">Transactions</li>
      <li v-if="page == 'ltc20'" :class="{ 'active': tab == 4 }" @click="showTab(4)">Info</li>
    </ul>
    <div v-if="tab == 0" style="display: flex; align-items: center;">
      <span v-if="isSweep">Items to sweep:</span>
      <input v-if="isSweep" class="modal-input" type="number" style="padding: 0.25rem; margin: 0px 1rem;" min="0" max="20" @input="onSweepInput">
      <button v-if="!isSweep && !isBulkBuy && page != 'runes'" class="option-button" style="margin: 0px 0.5rem;" @click="showSweep">
        <font-awesome-icon :icon="['fas', 'broom']" />
        <tippy to="parent">Sweep</tippy>
      </button>
      <button v-if="!isSweep && !isBulkBuy && page != 'runes'" class="option-button" style="margin: 0px 0.5rem;" @click="showBulkBuy">
        <font-awesome-icon :icon="['fas', 'cart-shopping']" />
        <tippy to="parent">Bulk Buy</tippy>
      </button>
      <button v-if="page == 'collection' || page == 'litemaps'" class="option-button" style="margin: 0px 0.5rem;">
        <font-awesome-icon :icon="['fas', 'arrow-up-wide-short']" />
        <tippy interactive trigger="click" arrow="false" placement="bottom-end" to="parent" content-tag="div" theme="dpn">
          <div :class="{ 'menu-opt': true, 'active': (this.sort_listings == 'price' && this.order_listings == 'asc') }" @click="sort('price', 'asc')">
            Price: lowest first<font-awesome-icon v-if="this.sort_listings == 'price' && this.order_listings == 'asc'" :icon="['fas', 'check']" style="margin-left: 1rem; color: #FFF" />
          </div>
          <div :class="{ 'menu-opt': true, 'active': (this.sort_listings == 'price' && this.order_listings == 'desc') }" @click="sort('price', 'desc')">
            Price: highest first<font-awesome-icon v-if="this.sort_listings == 'price' && this.order_listings == 'desc'" :icon="['fas', 'check']" style="margin-left: 1rem; color: #FFF" />
          </div>
          <div :class="{ 'menu-opt': true, 'active': (this.sort_listings == 'time') }" @click="sort('time', 'desc')">
            Recently listed<font-awesome-icon v-if="this.sort_listings == 'time'" :icon="['fas', 'check']" style="margin-left: 1rem; color: #FFF" />
          </div>
          <div :class="{ 'menu-opt': true, 'active': (this.sort_listings == 'inscription') }" @click="sort('inscription', 'asc')">
            Inscription number: lowest first<font-awesome-icon v-if="this.sort_listings == 'inscription'" :icon="['fas', 'check']" style="margin-left: 1rem; color: #FFF" />
          </div>
          <div v-if="collection" :class="{ 'menu-opt': true, 'active': (this.sort_listings == 'rarity') }" @click="sort('rarity', 'asc')">
            Rarity score: highest first<font-awesome-icon v-if="this.sort_listings == 'rarity' && this.order_listings == 'asc'" :icon="['fas', 'check']" style="margin-left: 1rem; color: #FFF" />
          </div>
          <div v-if="collection" :class="{ 'menu-opt': true, 'active': (this.sort_listings == 'rarity') }" @click="sort('rarity', 'desc')">
            Rarity score: lowest first<font-awesome-icon v-if="this.sort_listings == 'rarity' && this.order_listings == 'desc'" :icon="['fas', 'check']" style="margin-left: 1rem; color: #FFF" />
          </div>
          <div v-if="page == 'litemaps'" :class="{ 'menu-opt': true, 'active': (this.sort_listings == 'litemap') }" @click="sort('litemap', 'asc')">
            Litemap number: lowest first<font-awesome-icon v-if="this.sort_listings == 'litemap'" :icon="['fas', 'check']" style="margin-left: 1rem; color: #FFF" />
          </div>
        </tippy>
      </button>
    </div>
  </div>
  <div v-if="tab == 0" class="cards-wrapper">
    <p v-if="listings.length == 0" style="text-align: center;">No listings found</p>
    <div :class="{'cards': true, 'bulk-select': isBulkBuy }">
      <div v-for="item in listings" :key="item.inscription_id" :class="{ 'listing': true, 'selected': isInscriptionSelected(item.inscription_id) }" @click="isBulkBuy && !item.pending ? inscriptionSelected(item.inscription_id, item.price) : null">
        <router-link v-if="page == 'collection' || page == 'litemaps' || page == 'ltc20'" :to="{ name: 'inscription', params: { id: item.inscription_id }}">
          <div class="inscription-number">#{{ item.inscription_number }}</div>
          <!-- <div v-if="item.rarity" class="rarity">{{ item.rarirty }}</div> -->
          <div v-if="item.rarity" class="rarity" >
            {{ item.rarity }}
            <tippy to="parent" arrow="false" content-tag="div" theme="dpn">
              <div>Rarity</div>
              <div v-if="item.rarity / collection.supply * 100 <= 1">Top 1%</div>
              <div v-if="item.rarity / collection.supply * 100 > 1 && item.rarity / collection.supply * 100 <= 10">Top 10%</div>
              <div v-if="item.rarity / collection.supply * 100 > 10 && item.rarity / collection.supply * 100 <= 50">Top 50%</div>
              <div v-if="item.rarity / collection.supply * 100 > 50">Bottom 50%</div>
            </tippy>
          </div>
          <img v-if="page == 'collection' && collection.type == 0" class="inscription-img" loading="lazy" :src="'https://ordinalslite.com/content/' + item.inscription_id">
          <div v-if="page == 'collection' && collection.type == 1" class="inscription-img inscription-iframe">
            <iframe scrolling="no" loading="lazy" style="transform: scale(0.5); transform-origin: 0 0; width: 200%; height: 500%; border: 0;" :src="'https://ordinalslite.com/preview/' + item.inscription_id"></iframe>
          </div>
          <div v-if="page == 'litemaps'" class="listing-item">{{ item.domain }}</div>
          <div v-if="page == 'ltc20'" style="padding: 0.75rem 0.75rem 0px;">
            <div class="ticker">{{ ltc20.ticker }}</div>
            <div class="amount">
              <span style="font-weight: 700; overflow-wrap: anywhere;">{{ item.ltc20_amount }}</span>
            </div>
            <div class="price-per-token">
              <div style="font-size: 1.1rem;">
                <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>{{ (satToBtc(item.price) / Number(item.ltc20_amount)).toLocaleString('en-US', { maximumFractionDigits: 8 }) }}/{{ ltc20.ticker }}</div>
                <div class="usd-value" style="font-size: 0.95em;">(${{ (satToBtc(item.price / Number(item.ltc20_amount)) * usd_rate).toFixed(2) }}/{{ ltc20.ticker }})</div>
            </div>
          </div>
        </router-link>
        <div v-if="page == 'runes'" style="padding: 0.75rem 0.75rem 0px;">
            <div class="ticker">{{ runes.spaced_rune }}</div>
            <div class="amount">
              <span style="font-weight: 700; overflow-wrap: anywhere;">{{ amountToDecimal(Number(item.rune_amount), runes.divisibility) }}</span>
            </div>
            <div class="price-per-token">
              <div style="font-size: 1.1rem;">
                <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>{{ (satToBtc(item.price) / amountToDecimal(Number(item.rune_amount), runes.divisibility)).toLocaleString('en-US', { maximumFractionDigits: 8 }) }}/{{ runes.symbol }}</div>
                <div class="usd-value" style="font-size: 0.95em;">(${{ (satToBtc(item.price / amountToDecimal(Number(item.rune_amount), runes.divisibility)) * usd_rate).toFixed(2) }}/{{ runes.symbol }})</div>
            </div>
          </div>
        <div class="listing-body">
          <div class="listing-info">
            <span>Seller:</span>
            <span>
              <router-link :to="{ name: 'address', params: { address: item.seller_address }}">{{ short_address(item.seller_address) }}</router-link>
            </span>
          </div>
          <div class="listing-price">
            <img src="@/assets/Litecoin.png" alt="litecoin" class="litecoin-icon"/>{{ satToBtc(item.price) }}
            <span class="usd-value">(${{ (satToBtc(item.price) * usd_rate).toFixed(2) }})</span>
          </div>
          <button class="buy-button" @click="isBulkBuy ? null : buyItem(item.inscription_id, item.price)" :disabled="item.pending">
            <font-awesome-icon v-if="item.pending" class="fa-spin" style="margin-right: 0.5rem" :icon="['fas', 'spinner']" />  
            {{ item.pending ? 'Pending' : 'Buy' }}
          </button>
        </div>
      </div>
    </div>
    <InfiniteScrollComponent v-if="allowLoadListings" @infinite="loadListings" />
  </div>
  <div v-if="tab == 1" class="table-container">
    <div style="margin-bottom: 1rem;">
      <div style="display: flex; justify-content: space-between;">
        <span>Sales</span>
        <span>Previous Month</span>
      </div>
      <SalesChartComponent :items="activity"/>
    </div>
    <ActivityTableComponent :items="activity" :rune="runes"/>
    <InfiniteScrollComponent v-if="allowLoadActivity" @infinite="loadActivity" />
  </div>
  <div v-if="tab == 2" class="table-container">
    <HoldersTableComponent :items="holders" :supply="Number(this.ltc20.supply)" :price="listings[0] ? Number(listings[0].price) : 0"/>
    <InfiniteScrollComponent v-if="allowLoadHolders" @infinite="loadHolders" />
  </div>
  <div v-if="tab == 3" class="table-container">
    <TransactionsTableComponent :items="transactions"/>
    <InfiniteScrollComponent v-if="allowLoadTransactions" @infinite="loadTransactions" />
  </div>
  <div v-if="tab == 4" class="table-container">
    <Ltc20InfoComponent :token="ltc20"/>
  </div>
</template>

<script>
import { inject } from 'vue';
import { useToast } from "vue-toastification";
import config from '@/config.js'
import utils from '@/utils';

import InfiniteScrollComponent from '@/components/InfiniteScrollComponent.vue';
import ActivityTableComponent from '@/components/ActivityTableComponent.vue';
import SalesChartComponent from '@/components/SalesChartComponent.vue';
import HoldersTableComponent from '@/components/HoldersTableComponent.vue';
import TransactionsTableComponent from '@/components/TransactionsTableComponent.vue';
import Ltc20InfoComponent from '@/components/Ltc20InfoComponent.vue';

export default {
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      isModalVisible: false,
      tab: 0,
      page_number: 1,
      page_number_activity: 1,
      page_number_holders: 1,
      page_number_transactions: 1,
      sort_listings: 'price',
      order_listings: 'asc',
      allowLoadListings: true,
      allowLoadActivity: true,
      allowLoadHolders: true,
      allowLoadTransactions: true,
      headerImageError: false,
      isSweep: false,
      bulkPrice: 0,
      isBulkBuy: false,
      selected_inscriptions: [],
      usd_rate: inject('usd_rate', 0),
      block_height: inject('block_height', 0),
      page: "collection",
      collection_name: null,
      collection: {},
      ltc20: {},
      runes: {},
      price_metrics: {},
      listings: [],
      total: 0,
      activity: [],
      holders: [],
      transactions: [],
    };
  },
  async beforeRouteEnter(to, from, next) {
    console.log(to);
    let collection = {};
    let ltc20 = {};
    let runes = {};
    let listings = [];
    let activity = [];
    let total = 0;

    if (to.name == "collection") {
      [collection, listings] = await Promise.all([
        fetch(`${config.apiUrl}/collections/${to.params.name}`).then(d => d.json()),
        fetch(`${config.apiUrl}/listings/collections?collection=${to.params.name}&page=1`).then(d => d.json())
      ]);
      activity = await fetch(`${config.apiUrl}/market/events?type=sell&collection=${collection.result.id}`).then(d => d.json());

      total = listings.result.total;
      listings = listings.result.items;
    } else if (to.name == "litemaps") {
      [listings, activity] = await Promise.all([
        fetch(`${config.apiUrl}/listings/litemaps?page=1&size=30`).then(d => d.json()),
        fetch(`${config.apiUrl}/market/events?type=sell`).then(d => d.json())
      ]);
      total = listings.result.total;
      listings = listings.result.items;
    } else if (to.name == 'ltc20') {
      ltc20 = await fetch(`${config.apiUrl}/ltc20/${to.params.name}`)
        .then(d => d.json());
      if (ltc20.status == 0) {
        next(from);
        console.log("ltc20 not found");
        return;
      }

      [listings, activity] = await Promise.all([
        fetch(`${config.apiUrl}/listings/ltc20?ticker=${to.params.name}&page=1`).then(d => d.json()),
        fetch(`${config.apiUrl}/market/events?type=sell&ltc20=${to.params.name}`).then(d => d.json())
      ]);
      listings = listings.result.items;
    } else if (to.name == 'runes') {
      runes = await fetch(`${config.apiUrl}/runes/${to.params.name}`)
        .then(d => d.json());
      if (runes.status == 0) {
        next(from);
        console.log("rune not found");
        return;
      }

      [listings, activity] = await Promise.all([
        fetch(`${config.apiUrl}/listings/runes?rune=${to.params.name}&page=1`).then(d => d.json()),
        fetch(`${config.apiUrl}/market/events?type=sell&rune=${to.params.name}`).then(d => d.json())
      ]);
      listings = listings.result.items;
    }

    next(vm => {
      vm.collection = collection.result;
      vm.collection_name = to.params.name;
      vm.ltc20 = ltc20.result;
      vm.runes = runes.result;
      vm.listings = listings;
      vm.activity = activity.result;
      vm.page = to.name;
      vm.total = total;
    });
  },
  beforeRouteLeave(to, from, next) {
    this.emitter.emit("select-bar-close");
    next();
  },
  watch: {
    '$route': function(to, from) {
      if (to.name === 'ltc20' && to.params.name !== from.params.name) {
        this.fetch_ltc20_page(to.params.name, from);
      }
    }
  },
  components: {
    InfiniteScrollComponent,
    ActivityTableComponent,
    SalesChartComponent,
    HoldersTableComponent,
    TransactionsTableComponent,
    Ltc20InfoComponent
  },
  methods: {
    async showTab(number) {
      this.tab = number;

      if (number == 2) {
        var holders_resp = await fetch(`${config.apiUrl}/ltc20/holders?ticker=${this.ltc20.ticker}&page=1`)
          .then(d => d.json());
        this.holders = holders_resp.result;
      } else if (number == 3) {
        var transactions_resp = await fetch(`${config.apiUrl}/ltc20/transactions?ticker=${this.ltc20.ticker}&page=1`)
          .then(d => d.json());
        this.transactions = transactions_resp.result;
      }
    },
    async sort(sort, order = 'asc') {
      // order: asc, desc

      this.sort_listings = sort;
      this.order_listings = order;

      this.page_number = 1;
      this.allowLoadListings = true;

      var listings = null;
      if (this.page == 'collection') {
        listings = await fetch(`${config.apiUrl}/listings/collections?collection=${this.collection_name}&sort=${sort}&order=${order}&page=1`)
          .then(d => d.json());
      } else if (this.page == 'litemaps') {
        listings = await fetch(`${config.apiUrl}/listings/litemaps?sort=${sort}&order=${order}&page=1&size=30`)
          .then(d => d.json());
      } else {
        return;
      }

      this.listings = listings.result.items;
    },
    async loadListings() {
      if (!this.allowLoadListings || this.listings.length < 30) {
        return;
      }

      var listings_paged = null

      if (this.page == 'collection') {
        listings_paged = await fetch(`${config.apiUrl}/listings/collections?collection=${this.collection_name}&sort=${this.sort_listings}&order=${this.order_listings}&page=${++this.page_number}`)
        .then(d => d.json());
      } else if (this.page == 'litemaps') {
        listings_paged = await fetch(`${config.apiUrl}/listings/litemaps?page=${++this.page_number}&size=30`)
          .then(d => d.json());
      } else if (this.page == 'ltc20') {
        listings_paged = await fetch(`${config.apiUrl}/listings/ltc20?ticker=${this.ltc20.ticker}&page=${++this.page_number}`)
        .then(d => d.json());
      } else {
        return;
      }
      
      if (listings_paged.status == 1) {
        if (listings_paged.result.items.length < 30) {
          this.allowLoadListings = false;
        }

        this.listings.push(...listings_paged.result.items);
      }
    },
    async fetch_ltc20_page(ticker) {
      var ltc20 = await fetch(`${config.apiUrl}/ltc20/${ticker}`)
        .then(d => d.json());
      if (ltc20.status == 0) {
        console.log("ltc20 not found");
        this.toast.error(`${ticker} not found`, {
          position: "bottom-right"});
        return;
      }
      var [listings, activity] = await Promise.all([
        fetch(`${config.apiUrl}/listings/ltc20?ticker=${ticker}&page=1`).then(d => d.json()),
        fetch(`${config.apiUrl}/market/events?type=sell&ltc20=${ticker}`).then(d => d.json())
      ]);
        
      listings = listings.result.items;

      this.collection_name = ticker;
      this.ltc20 = ltc20.result;
      this.listings = listings;
      this.activity = activity.result;
    },
    async loadActivity() {
      if (!this.allowLoadActivity || this.activity.length < 20) {
        return;
      }

      var events_paged = null;
      if (this.page == 'collection') {
        events_paged = await fetch(`${config.apiUrl}/market/events?type=sell&collection=${this.collection.id}&page=${++this.page_number_activity}`)
          .then(d => d.json());
      } else if (this.page == 'ltc20') {
        events_paged = await fetch(`${config.apiUrl}/market/events?type=sell&ltc20=${this.ltc20.ticker}&page=${++this.page_number_activity}`)
          .then(d => d.json());
      } else if (this.page == 'runes') {
        events_paged = await fetch(`${config.apiUrl}/market/events?type=sell&rune=${this.runes.spaced_rune}&page=${++this.page_number_activity}`)
        .then(d => d.json());
      } else {
        return;
      }
      
      if (events_paged.status == 1) {
        if (events_paged.result.length < 20) {
          this.allowLoadListings = false;
        }

        this.activity.push(...events_paged.result);
      }
    },
    async loadHolders() {
      if (!this.allowLoadHolders || this.holders.length < 20 || this.page != 'ltc20') {
        return;
      }

      var holders_paged = await fetch(`${config.apiUrl}/ltc20/holders?ticker=${this.ltc20.ticker}&page=${++this.page_number_holders}`)
        .then(d => d.json());
      
      if (holders_paged.status == 1) {
        if (holders_paged.result.length < 20) {
          this.allowLoadHolders = false;
        }

        this.holders.push(...holders_paged.result);
      }
    },
    async loadTransactions() {
      if (!this.allowLoadTransactions || this.transactions.length < 20 || this.page != 'ltc20') {
        return;
      }

      var transactions_paged = await fetch(`${config.apiUrl}/ltc20/transactions?ticker=${this.ltc20.ticker}&page=${++this.page_number_transactions}`)
        .then(d => d.json());
      
      if (transactions_paged.status == 1) {
        if (transactions_paged.result.length < 20) {
          this.allowLoadTransactions = false;
        }

        this.transactions.push(...transactions_paged.result);
      }
    },
    buyItem(itemId, price) {
      console.log(`Buying item with ID ${itemId} for ${price}`);
      if (this.page == 'runes') {
        this.emitter.emit("modal-buy-open", { listings: [itemId], runes: this.runes });
      } else {
        this.emitter.emit("modal-buy-open", { listings: [itemId]});
      }
    },
    onSweepInput(event) {
      var numberOfItems = Math.min(event.target.value, this.listings.length);
      if (numberOfItems > 20) {
        numberOfItems = 20;
        event.target.value = 20;
      }
      this.selected_inscriptions = [];
      this.bulkPrice = 0;
      
      var itemsCount = numberOfItems;

      for (var i = 0; i < itemsCount; i++) {
        const item = this.listings[i];
        if (item.pending) {
          itemsCount++;
          continue;
        }

        this.selected_inscriptions.push(item.inscription_id);
        this.bulkPrice += Number(item.price);
      }
      this.emitter.emit("select-bar-open", { inscription_ids: this.selected_inscriptions, bulk_mode: true, buying: true, sweep_mode: true, price_total: this.bulkPrice });
    },
    showSweep() {
      this.isSweep = true;
      this.selected_inscriptions = [];
      this.emitter.emit("select-bar-open", { inscription_ids: this.selected_inscriptions, bulk_mode: true, buying: true, sweep_mode: true });
    },
    showBulkBuy() {
      this.isBulkBuy = true;
      this.selected_inscriptions = [];
      this.emitter.emit("select-bar-open", { inscription_ids: this.selected_inscriptions, bulk_mode: true, buying: true });
    },
    inscriptionSelected(inscription_id, price = 0) {
      const index = this.selected_inscriptions.indexOf(inscription_id);
      if (index === -1) {
        this.bulkPrice += Number(price);
        this.selected_inscriptions.push(inscription_id);
      } else {
        this.selected_inscriptions.splice(index, 1);
        this.bulkPrice -= Number(price);
      }
      this.emitter.emit("select-bar-open", { inscription_ids: this.selected_inscriptions, bulk_mode: true, buying: true, price_total: this.bulkPrice });
    },
    isInscriptionSelected(inscription_id) {
      return this.selected_inscriptions.includes(inscription_id);
    },
    handleImageError() {
      this.headerImageError = true;
    },
    loadImage(token) {
      if (token == 'lite') {
        return require(`@/assets/ltc20/${token}.jpg`);
      } else if (token == 'labs' || token == 'iltc' || token == 'elks' || token == '1337' || token == 'hodl') {
        return require(`@/assets/ltc20/${token}.png`);
      }
      return null;
    },
    ...utils,
  },
  mounted() { 
    this.emitter.on("select-bar-closed", () => {
      this.isBulkBuy = false;
      this.isSweep = false;
      this.bulkPrice = 0;
      this.selected_inscriptions = [];
    });
    this.emitter.on("listing-pending", (parameters) => {
      this.listings.forEach(item => {
        if (item.inscription_id === parameters.inscription_id) {
          item.pending = true;
        }
      });
    });
  },
};
</script>

<style scoped>
.listings-header {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.header-image {
  display: flex;
  flex-shrink: 0;
  margin-right: 1.5rem;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  object-fit: cover;
  image-rendering: pixelated;
  align-items: center;
  justify-content: center;
  color: #FFF;
  background-color: #212121;
  font-size: 1.4rem;
  font-weight: 600;
}

.socials {
  margin-top: 1rem;
}

.socials a {
  margin-right: 1rem;
}

.tabs-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  overflow-x: auto;
}

.option-button {
  padding: 0.3rem;
  background: none;
  line-height: 0;
  border-radius: 8px;
}

button:not(:disabled):hover {
  background-color: #222;
}

.name {
  font-size: 2.3rem;
  margin: 0;
}

.description {
  margin-top: 0.5rem;
  color: #fffc;
}

.details {
  margin-bottom: 2rem;
}

.details>div {
  display: flex;
  flex-wrap: wrap;
  margin-left: -3rem;
}

.detail-item {
  margin-bottom: 0.5rem;
  margin-left: 3rem;
}

.detail-value {
  font-weight: 700;
}

.detail-text {
  font-size: 90%;
  line-height: 1;
  color: #fffc;
}

.amount {
  text-align: center;
  font-size: 1.5rem;
}

.price-per-token {
  text-align: center;
  margin: 1rem 0;
}

.listing {
  padding: 0;
}

.listing-item {
  padding-top: 2rem;
}

.listing-body {
  padding: 0.75rem;
}

img.inscription-img {
  object-fit: cover;
}

.inscription-iframe {
  min-height: 240px;
  overflow: hidden;
}

.cards.bulk-select>div {
  cursor: pointer;
}

.cards.bulk-select>div a {
  pointer-events: none;
}

.listing.selected {
  transform: scale(.9);
  border: 2px solid #007aff;
}

.square-img {
  border-radius: 0;
}

.item-container {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.buy-button {
  height: 30px;
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  background-color: #D4D6E1;
  font-weight: 700;
  color: #345D9D;
  cursor: pointer;
  transition: all .25s ease-in-out;
}

.action-sell {
  color: #00d181;
  background-color: #00d1814d
}

.action-list {
  color: #027dff;
  background-color: #027dff4d
}

.action-unlist {
  color: #dc3545;
  background-color: #dc35454d
}

.action-label {
  font-size: .8rem;
  padding: 0.2rem 0.4rem;
  border-radius: 6px;
}
</style>